const UpAni = () => {
    return `
    opacity: 0;
    transform: translateY(15%);

    &.on{
        opacity: 1;
        transform: translateY(0%);
        transition: ease-in-out .6s;
    }
    `
}

export {UpAni};