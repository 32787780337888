import { useEffect, useState } from "react";

//css
import styled from "styled-components";

// component

// img
import BackgroundImg from "../../assets/img/second_background.png";
import FormImg from "../../assets/img/form_img.png";
import WalaFirstIcon from "../../assets/img/icon/icon_wala_01.svg";
import WalaSecondIcon from "../../assets/img/icon/icon_wala_02.svg";
import AppStoreImg from "../../assets/img/store_button.png";
import GooglePlayImg from "../../assets/img/google_button.png";

import Axios from "../../lib/serverController";

export default function SectionF() {
  const selectList = ["입점/제휴", "투자", "채용", "홍보"]

  const handleSelect = (e) => {
    setSelected(e.target.value);
  }

  // useState
  const [send, setSend] = useState(false);
  const [event, setEvent] = useState(false);

  // 문의 셀렉트
  const [Selected, setSelected] = useState("입점/제휴");

  // 이름, 소속, 전화번호, 이메일, 제목, 문의 내용 state
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [contactMsg, setContactMsg] = useState("");

  // validation text
  const [nameValidation, setNameValidation] = useState(false);
  const [deptValidation, setDeptValidation] = useState(false);
  const [concatValidation, setConcatValidation] = useState(false);
  const [emaileValidation, setEmailValidation] = useState(false);
  const [titleValidation, setTitleValidation] = useState(false);
  const [contactValidation, setContactValidation] = useState(false);

  const emailValidation =
    
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

  
  // useEffect

  // 문의하기 클릭 시, 문의 완료로 바뀌고 3초 뒤 다시 문의하기 텍스트로 변경
  useEffect(() => {
    if (send) {
      setEvent(true);
      setTimeout(() => {
        setEvent(false);
        setSend(!send);
        setName("");
        setDept("");
        setPhone("");
        setEmail("");
        setTitle("");
        setContactMsg("");
      }, 3000);
    }
  }, [send]);

  // input변경 useEffect
  useEffect(() => {
    if (name) setNameValidation(false);
    if (dept) setDeptValidation(false);
    if (phone.length > 0 && phone.length < 20) setConcatValidation(false);
    if (emailValidation.test(email)) setEmailValidation(false);
    if (title) setTitleValidation(false);
    if (contactMsg) setContactValidation(false);
  }, [name, dept, phone, email, title, contactMsg]);

  // validation function
  const validation = () => {
    if (name) setNameValidation(false);
    else setNameValidation(true);

    if (dept) setDeptValidation(false);
    else setDeptValidation(true);

    if (phone.length > 0 && phone.length < 20) setConcatValidation(false);
    else setConcatValidation(true);

    if (emailValidation.test(email)) setEmailValidation(false);
    else setEmailValidation(true);

    if (title) setTitleValidation(false);
    else setTitleValidation(true);

    if (contactMsg) setContactValidation(false);
    else setContactValidation(true);
  };

  const onSend = () => {
    if (name && dept && phone && email && title && contactMsg) {
      const sendData = new FormData();

      if (
        name &&
        dept &&
        phone.length > 0 &&
        phone.length < 20 &&
        emailValidation.test(email) &&
        title &&
        contactMsg
      ) {
        sendData.append("contact_name", name);
        sendData.append("contact_dept", dept);
        sendData.append("contact_phone", phone);
        sendData.append("contact_email", email);
        sendData.append("title_content", title);
        sendData.append("contact_content", contactMsg);

      let selectedType = 0;
      switch (Selected) {
        case "입점/제휴":
          selectedType = 1;
          break;
        case "투자":
          selectedType = 2;
          break;
        case "채용":
          selectedType = 3;
          break;
        case "홍보":
          selectedType = 4;
          break;
        default:
          selectedType = 0;
          break;
        }
        
        Axios.post(`https://wala.land:8071/api/affiliation`, {
          type: selectedType,
          name: name,
          level: dept,
          tel: phone,
          email: email,
          title: title,
          content: contactMsg,
        })
          .then((res) => {
            console.log(res);
            setSend(!send);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  // email validation

  return (
    <Container>
      <FormWrapper>
        <FormInner>
          <FormArea>
            <MainText>입점문의</MainText>
            <Form>
              <InputCon>
                <TitleBox>
                  <InputTit>
                    문의 유형*
                  </InputTit>
                  <WarningText>
                    *필수입력 사항
                  </WarningText>
                </TitleBox>
                <SelectArea>
                  <SelectBox onChange={handleSelect} value={Selected}>
                    {selectList.map((optionItem)=> (
                      <Option value={optionItem} key={optionItem}>
                        {optionItem}
                      </Option>
                    ))}
                  </SelectBox>
                </SelectArea>
              </InputCon>

              <InputArea>
                <InputCon>
                  <TitleBox>
                    <InputTit>
                      회사명*
                    </InputTit>
                    <WarningText validation={deptValidation}>
                      *필수입력 사항
                    </WarningText>
                  </TitleBox>
                  <Input
                    type="text"
                    maxLength={50}
                    placeholder={
                      "회사명"
                    }
                    value={dept}
                    onChange={(e) => {
                      setDept(e.target.value);
                    }}
                  />
                </InputCon>

                <InputCon>
                  <TitleBox>
                    <InputTit>
                      담당자*
                    </InputTit>
                    <WarningText validation={nameValidation}>
                      *필수입력 사항
                    </WarningText>
                  </TitleBox>
                  <Input
                    type="text"
                    maxLength={50}
                    placeholder={"이름"}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </InputCon>
              </InputArea>

              <InputArea>
                <InputCon>
                  <TitleBox>
                    <InputTit>
                      이메일*
                    </InputTit>
                    <WarningText validation={emaileValidation}>
                      *필수입력 사항
                    </WarningText>
                  </TitleBox>
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder={
                      "example@walaland.com"
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputCon>

                <InputCon>
                  <TitleBox>
                    <InputTit>
                      연락처*
                    </InputTit>
                    <WarningText validation={concatValidation}>
                      *필수입력 사항
                    </WarningText>
                  </TitleBox>
                  <Input
                    type="text"
                    maxLength={20}
                    placeholder={"01012345678"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value.replace(/[^0-9]{9,11}/))}
                  />
                </InputCon>
              </InputArea>


              <InputCon>
                <TitleBox>
                  <InputTit>제목*</InputTit>
                  <WarningText className="warning" validation={titleValidation}>
                    *필수입력 사항
                  </WarningText>
                </TitleBox>
                <Textarea className="title-textarea"
                  placeholder={
                    "제목을 입력해주세요."
                  }
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></Textarea>
              </InputCon>

              <InputCon className="last-box">
                <TitleBox>
                  <InputTit>문의내용*</InputTit>
                  <WarningText className="warning" validation={contactValidation}>
                    *필수입력 사항
                  </WarningText>
                </TitleBox>
                <Textarea
                  placeholder={
                    "문의 사항을 입력해주세요."
                  }
                  value={contactMsg}
                  onChange={(e) => setContactMsg(e.target.value)}
                ></Textarea>
              </InputCon>

              <SubmitBtn
                onClick={() => {
                  onSend();
                  validation();
                }}
                active={event}
              >
                {event
                  ? "전송완료"
                  : "전송하기"}
              </SubmitBtn>
            </Form>
          </FormArea>
          <ImgArea>
            <Img src={FormImg} />
          </ImgArea>
        </FormInner>
      </FormWrapper>
      {/* <MailImgCon src={MailImg} alt="" />
      <BgImgCon src={BgImg} alt="" />
      <MbBgImgCon src={MbBgImg} alt="" /> */}

      <AppWrapper>
        <Background src={BackgroundImg} />

        <AppArea>
          <AppInner className="second-order">
            <InnerTitle className="pc-title">FOR SHOPPING</InnerTitle>
            <AppIcon>
              <Icon src={WalaFirstIcon} />
            </AppIcon>
            <LinkBox>
              
              <InnerTitle className="mobile-title">FOR SHOPPING</InnerTitle>
              <AppStoreLink href="https://apps.apple.com/us/app/wala-%EC%99%88%EB%9D%BC/id6443930204" target="_blank">
                <AppStoreLinkImg src={AppStoreImg} />
              </AppStoreLink>
              <GooglePlayLink href="https://play.google.com/store/apps/details?id=com.walaland&hl=ko&gl=US" target="_blank"> 
                <GooglePlayLinkImg src={GooglePlayImg} />
              </GooglePlayLink>
            </LinkBox>
          </AppInner>
          <DownloadText className="first-order">앱 <Point>다운로드</Point></DownloadText>
          <AppInner className="third-order">
            <InnerTitle className="pc-title">FOR BRAND</InnerTitle>
            <AppIcon>
              <Icon src={WalaSecondIcon} />
            </AppIcon>
            <LinkBox>
              <InnerTitle className="mobile-title">FOR BRAND</InnerTitle>
              <AppStoreLink href="https://apps.apple.com/us/app/wala/id1605626301" target="_blank">
                <AppStoreLinkImg src={AppStoreImg} />
              </AppStoreLink>
              <GooglePlayLink href="https://play.google.com/store/apps/details?id=kr.com.wala" target="_blank">
                <GooglePlayLinkImg src={GooglePlayImg} />
              </GooglePlayLink>
            </LinkBox>
          </AppInner>
        </AppArea>
      </AppWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormWrapper = styled.div`
  height: calc(100vh * (656 / 1080));
  padding-top: calc(100vh * (100 / 1080));
  background: #f6f6f6;
  
  @media (max-width: 800px) {
    height: calc(100vh * (590 / 862));
    padding-top: calc(100vh * (80 / 862));
    overflow: hidden;
  }
  @media (max-width: 600px) {
    height: calc(100vh * (590 / 862));
    padding-top: calc(100vh * (60 / 862));
    overflow: hidden;
  }
`;

const FormInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 800px) {
    display: block;
  }
`

const FormArea = styled.div`
  width: calc(100vh * (423 / 1080));
  
  @media (max-width: 800px) {
    /* width: calc(100vh * (423 / 1080)); */
    margin: 0 auto;
  }
  
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 calc(100vh * (40 / 862));
  }
`;

const MainText = styled.h2`
  margin-bottom: calc(100vh * (10 / 1080));
  font-weight: 700;
  font-size: calc(100vh * (20 / 1080));
`;

const Form = styled.div`
`;
const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(100vh * (5 / 1080));
  
  @media (max-width: 800px) {
    display: block;
  }
`;

const InputCon = styled.div`
  width: 100%;
  margin-bottom: calc(100vh * (15 / 1080));

  &.last-box {
    margin-bottom: calc(100vh * (26 / 1080));
  }
  
  @media (max-width: 800px) {
    margin-bottom: calc(100vh * (7 / 862));

    &.last-box {
      margin-bottom: calc(100vh * (15 / 862));
    }
    
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: calc(100vh * (5 / 1080));

  
  @media (max-width: 800px) {
    margin-bottom: calc(100vh * (5 / 862));
  }
`;

const InputTit = styled.span`
  display: block;
  font-weight: 700;
  font-size: calc(100vh * (14 / 1080));
  color: #212121;

  
  @media (max-width: 800px) {
    font-size: calc(100vh * (14 / 862));
  }

`;

const Input = styled.input`
  width: 100%;
  height: calc(100vh * (35 / 1080));
  padding: 0 calc(100vh * (11 / 1080));
  font-size:calc(100vh * (13 / 1080));
  border-radius: calc(100vh * (5 / 1080));
  background-color: #fff;

  &::placeholder {
    color: #b6b6b6;
  }
  
  @media (max-width: 800px) {
    height: calc(100vh * (30 / 862));
    padding: 0 calc(100vh * (11 / 862));
    font-size: calc(100vh * (13 / 862));
  }
`;

const WarningText = styled.span`
  margin-left: calc(100vh * (8 / 1080));
  font-weight: 500;
  font-size: calc(100vh * (13 / 1080));
  color: #FF5353;


  display: ${(props) => (props.validation ? "block" : "none")};

  
  @media (max-width: 800px) {
    font-size: calc(100vh * (13 / 862));
  }
`;
const SelectArea = styled.div`
  padding: 0 calc(100vh * (16 / 1080));
  
  background-color: #fff;
  border-radius: calc(100vh * (5 / 1080));
  
  @media (max-width: 800px) {
    padding: 0 calc(100vh * (11 / 862));
  }
`
const SelectBox = styled.select`
  width: 100%;
  height: calc(100vh * (35 / 1080));
  border-radius: calc(100vh * (5 / 1080));
  font-weight: 500;
  font-size: calc(100vh * (13 / 1080));
  cursor: pointer;

  @media (max-width: 800px) {
    height: calc(100vh * (30 / 862));
    font-size: calc(100vh * (13 / 862));
  }
`;

const Option = styled.option`
  display: block;
  width: 100%;
  line-height: calc(100vh * (25 / 1080));
  font-weight: 500;
  font-size: calc(100vh * (13 / 1080));
  
  @media (max-width: 800px) {
    font-size: calc(100vh * (13 / 862));
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: calc(100vh * (12 / 1080)) calc(100vh * (11 / 1080));
  font-weight: 500;
  font-size: calc(100vh * (13 / 1080));
  border-radius: calc(100vh * (5 / 1080));
  background-color: #fff;

  &::placeholder {
    color: #b6b6b6;
  }
  &::-webkit-scrollbar {
    width: 0;
  }

  &.title-textarea {
    padding: calc(100vh * (10 / 1080));
    height: calc(100vh * (35 / 1080));
  }
  @media (max-width: 800px) {
    font-size: calc(100vh * (13 / 862));
    padding: calc(100vh * (12 / 862)) calc(100vh * (11 / 862));
  }
  &.title-textarea {
    padding: calc(100vh * (8 / 862)) calc(100vh * (11 / 862));
    height: calc(100vh * (30 / 862));
  }
`;

const SubmitBtn = styled.button`

  width: calc(100vh * (164 / 1080));
  height: calc(100vh * (48 / 1080));
  font-weight: 600;
  font-size: calc(100vh * (15 / 1080));
  color: #fff;
  background-color: #000;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ImgArea = styled.div`
  width: calc(100vh * (381 / 1080));
  margin-left: calc(100vh * (82 / 1080));
  
  @media (max-width: 800px) {
    display: none;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(100vh * (636 / 1080));
  height: calc(100vh * (424 / 1080));
  margin: 0 auto;

  
  @media (max-width: 800px) {
    height: calc(100vh * (275 / 862));
  }
`
const Background = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vh * (455 / 1080));
  height: calc(100vh * (455 / 1080));
  z-index: -1;

  
  @media (max-width: 800px) {
    width: calc(100vh * (365 / 862));
    height: calc(100vh * (365 / 862));
  }
`
const AppArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    gap: calc(100vh * (25 / 1080));
  }
`
const AppInner = styled.div`
  width: calc(100vh * (157 / 1080));

  @media (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(100vh * (24 / 862));
    width: calc(100vh * (262 / 862));

    &.second-order {
      order: 2;
    }
    &.third-order {
      order: 3;
    }
  }
`
const InnerTitle = styled.h3`
  margin-bottom: calc(100vh * (15 / 1080));
  font-weight: 700;
  font-size: calc(100vh * (20 / 1080));
  text-align: center;

  &.mobile-title {
    display: none;
  }

  
  @media (max-width: 800px) {
    text-align: left;
    margin-bottom: calc(100vh * (10 / 862));
    font-size: calc(100vh * (14 / 862));

    &.mobile-title {
      display: block;
    }
    &.pc-title {
      display: none;
    }
  }
`
const AppIcon = styled.div`
  width: 100%;
  height: calc(100vh * (157 / 1080));
  margin-bottom: calc(100vh * (15 / 1080));
  border-radius: calc(100vh * (28 / 1080));
  overflow: hidden;

  
  @media (max-width: 800px) {
    width: calc(100vh * (80 / 862));
    height: calc(100vh * (80 / 862));
    margin-bottom: 0;
    border-radius: calc(100vh * (18 / 862));
  }
`
const Icon = styled.img`
  width: 100%;
  height: 100%;
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    display: block;
    width: calc(100vh * (157 / 862));
  }
`
const AppStoreLink = styled.a`
  display: block;
  width: calc(100vh * (69 / 1080));
  height: calc(100vh * (23 / 1080));

  
  @media (max-width: 800px) {
    display: inline-block;
    width: calc(100vh * (71 / 862));
    height: calc(100vh * (23 / 862));
  }
`
const AppStoreLinkImg = styled.img``

const GooglePlayLink = styled.a`
  display: block;
  width: calc(100vh * (77 / 1080));
  height: calc(100vh * (23 / 1080));

  @media (max-width: 800px) {
    display: inline-block;
    width: calc(100vh * (78 / 862));
    height: calc(100vh * (23 / 862));
    margin-left: calc(100vh * (8 / 1080));
  }
`
const GooglePlayLinkImg = styled.img``

const DownloadText = styled.h2`
  font-weight: 800;
  font-size: calc(100vh * (36 / 1080));

  @media (max-width: 800px) {
    order: 1;
    font-size: calc(100vh * (20 / 862));
  }
`
const Point = styled.em`
  
  font: inherit;
	text-emphasis-style : dot;
`