import { useEffect, useState } from "react";

//css
import styled from "styled-components";

// component
import Header from "../component/common/header";
import MainBody from "./component/mainBody"; 
import Side from "../component/common/side";

export default function MainPage() {
  // useState
  const [isBool, setIsBool] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const isState = {
    state: isBool,
    setState: setIsBool,
  };

  const isColor = {
    color: isChange,
    setColor: setIsChange,
  };

  return (
    <>
      <Header
        state={isBool}
        color={isChange}
      />
      <MainBody isState={isState} isColor={isColor} />
      <Side />
    </>
  );
}
