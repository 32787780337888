import React, {useEffect, useState} from 'react';
import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

//css
import styled from "styled-components";

//img
import LogoImg from "../../assets/img/header_logo.png";
import AppleIcon from "../../assets/img/icon/icon_apple.svg";
import GoogleIcon from "../../assets/img/icon/icon_google.svg";
import MobileAppleIcon from "../../assets/img/icon/icon_apple_mb.svg";
import MobileGoogleIcon from "../../assets/img/icon/icon_google_mb.svg";
import MobileLoungeIcon from "../../assets/img/icon/icon_lounge_mb.svg"
import SnsMenuIcon from "../../assets/img/icon/icon_sns_menu.svg"
import SnsMenuCloseIcon from "../../assets/img/icon/icon_close_mb.svg"

export default function Header() {
  
    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
        setIsActive(!isActive);
    }
    
  return (
    <Container className="header">
      <Wrap>
        <LogoWrap>
          <Logo
            src={LogoImg}
            alt="logo"
            onClick={() => {
              $.fn.fullpage.moveTo(1);
            }}
          />
        </LogoWrap>

        <BtnWrap>
          <SnsLink
            href="https://apps.apple.com/us/app/wala-%EC%99%88%EB%9D%BC/id6443930204"
            target="_blank"
          >
            <SnsIcon src={AppleIcon} />
            <LinkText>App Store</LinkText>
          </SnsLink>

          <SnsLink
            href="https://play.google.com/store/apps/details?id=com.walaland&hl=ko&gl=US"
            target="_blank"
          >
            <SnsIcon src={GoogleIcon} />
            <LinkText>Google Play</LinkText>
          </SnsLink>

          <LoungeLink
            href="https://brand.wala.land/login"
            target="_blank"
          >
            파트너 라운지
          </LoungeLink>
        </BtnWrap>

        <MobileBtnWrapper>
          <MobileBtnControl onClick={toggleActive}>
            <ControlBtnImg  className={isActive ? "" : "active"} src={SnsMenuIcon} />
            <ControlBtnImg  className={isActive ? "active" : ""} src={SnsMenuCloseIcon} />
          </MobileBtnControl>
          <MobileBtnArea>
            <MobileSnsLink 
              href="https://apps.apple.com/us/app/wala-%EC%99%88%EB%9D%BC/id6443930204"
              target="_blank" className={isActive ? "active" : ""}>
              <ButtonImg src={MobileAppleIcon} />
            </MobileSnsLink>
            <MobileSnsLink 
              href="https://play.google.com/store/apps/details?id=com.walaland&hl=ko&gl=US"
              target="_blank" className={isActive ? "active" : ""}>
              <ButtonImg src={MobileGoogleIcon} />
            </MobileSnsLink>
            <MobileSnsLink href="https://brand.wala.land/login"
            target="_blank" className={isActive ? "active" : ""}>
              <ButtonImg src={MobileLoungeIcon} />
            </MobileSnsLink>
          </MobileBtnArea>
        </MobileBtnWrapper>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px calc(100vh * (4 / 1080)) calc(100vh * (15 / 1080)) rgba(0, 0, 0, 0.04);
    background-color: #fff;
`;

const Wrap = styled.div`
  width: 945px;
  padding: 0 calc(100vh * (25 / 1080));
  height: calc(100vh * (87 / 1080));
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 945px) {
    width: 100%;
    padding: calc(100vh * (25 / 1080));
  }
  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh * (56 / 852));
  }
/* 
  @media (max-width: 945px) and (orientation: portrait) {
  } */

  @media (max-width: 900px) {
  }
`;

const LogoWrap = styled.div`
  width: calc(100vh * (166 / 1080));

  @media (max-width: 1024px) and (orientation: portrait) {
    /* height: calc(100vh * (26 / 1080)); */
  }
  
  @media (max-width: 600px) {
    width: calc(100vh * (119 / 852));
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: calc(100vh * (42 / 1080));
`;

const SnsLink = styled.a`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    display: none;
  }
`;


const SnsIcon = styled.img`
  width: calc(100vh * (20 / 1080));
  height: calc(100vh * (20 / 1080));
`
const LinkText = styled.span`
  margin-left: calc(100vh * (6 / 1080));
  font-weight: 700;
  font-size: calc(100vh * (16 / 1080));
  
  @media (max-width: 945px) and (orientation: portrait) {
    font-size: calc(100vh * (16 / 852));
  }
`

const LoungeLink = styled.a`
  display: inline-block;
  padding: 0 calc(100vh * (20 / 1080));
  background: #000;
  border-radius: calc(100vh * (19 / 1080));
  font-weight: 700;
  font-size: calc(100vh * (16 / 1080));
  line-height: calc(100vh * (38 / 1080));
  color: #fff;

  @media (max-width: 945px) and (orientation: portrait) {
    font-size: calc(100vh * (16 / 1080));
  }
  
  @media (max-width: 600px) {
    display: none;
  }

`;

const MobileBtnWrapper = styled.div`
  display: none;

  
  @media (max-width: 600px) {
    position: fixed;
    top: calc(100vh * (76 / 862));
    right: calc(100vh * (25 / 862));
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(100vh * (11 / 862));
  }
`

const MobileBtnControl = styled.button`
  width: calc(100vh * (45 / 862));
  height: calc(100vh * (45 / 862));
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px calc(100vh * (3 / 862)) calc(100vh * (7 / 862)) calc(100vh * (2 / 862)) rgba(0, 0, 0, 0.07);
`

const ControlBtnImg = styled.img`
  display: none;
  width: 100%;
  height: 100%;
  

  &.active {
    display: block;
  }
`

const MobileBtnArea = styled.div`
`

const MobileSnsLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width:  calc(100vh * (38 / 862));
  height: calc(100vh * (38 / 862));
  opacity: 0;
  z-index: -2;

  &:not(:last-of-type) {
    margin-bottom: calc(100vh * (11 / 862));
  }

  &:first-of-type {
    transition: 0.2s;
    transform: translateY(calc(100vh * (-46 / 862)));

    &.active{
      z-index: 1;
      opacity: 1;
      transform: translateY(calc(100vh * (0 / 862)));
    }
  }

  &:nth-of-type(2) {
    transition: 0.4s;
    transition-delay: 0.1s;

    transform: translateY(calc(100vh * (-95 / 862)));
    
    &.active{
      z-index: 1;
      opacity: 1;
      transform: translateY(calc(100vh * (0 / 862)));
    }
  }

  &:last-of-type {
    transition: 0.6s;
    transition-delay: 0.2s;

    transform: translateY(calc(100vh * (-144 / 862)));
    
    &.active{
      z-index: 1;
      opacity: 1;
      transform: translateY(calc(100vh * (0 / 862)));
    }

  }
`
const ButtonImg = styled.img`
  width: 100%;
  height: 100%;
`