import { useEffect } from "react";
import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

//css
import styled from "styled-components";

// component
import SectionA from "./sectionA";
import SectionB from "./sectionB";
import SectionC from "./sectionC";
import SectionD from "./sectionD";
import SectionE from "./sectionE";
import SectionF from "./sectionF";
import Footer from "../../component/common/footer";

// img

export default function MainBody({ isState, isColor, language }) {
  // 섹션별 로고 색 변경
  const { state, setState } = isState;
  const { color, setColor } = isColor;

  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  const setVh = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight}px`
    );
  };
  window.addEventListener("resize", setVh);
  setVh();

  // fullpage.js
  $(() => {
    const nowWid = $(window).width();
    // 현재 브라우저 사이즈가 1024px 일 때
    $(window).on('resize', function () {
        const updateWid = $(window).width();
        if((nowWid > 1025 && updateWid < 1025) || (nowWid < 1025 && updateWid > 1025)){
            window.location.reload();
        }
    });

    $("#fullpage").fullpage({
      navigation: true,

      afterLoad: function (anchorLink, index) {
        const nowWid = $(window).width();
        // section1 일 때 한영 버튼 색 변경
        if (index == 1) {
          setColor(true);
        } else if (index == 4) {
          // section4 일 때 한영 버튼 색 변경, 로고 색 변경, section4에 있는 큰 로고 fade in
          setState(true);
          setColor(true);
          // $('.logoOpacity').addClass('on');
        } else {
          // 다른 섹션일 때 초기화
          setState(false);
          setColor(false);
          // $('.logoOpacity').removeClass('on');
        }

        // section2 일 때 애니메이션
        if (index == 2) {
          $(".sec2_ani").addClass("on");
        } else {
          $(".sec2_ani").removeClass("on");
        }

        // section3 일 때 애니메이션
        if (index == 3) {
          $(".sec3_ani").addClass("on");
        } else {
          $(".sec3_ani").removeClass("on");
        }

        // section4 일 때 애니메이션
        if (index == 4) {
          $(".sec4_ani").addClass("on");
        } else {
          $(".sec4_ani").removeClass("on");
        }

        // section5 일 때 애니메이션
        if (index == 5) {
          $(".sec5_ani").addClass("on");
        } else {
          $(".sec5_ani").removeClass("on");
        }

        // 푸터에서 헤더 배경 색 적용
        if (nowWid < 1024 && index == 7) {
          $(".header").addClass("on");
          $(".guideBtn").addClass("on");
        } else {
          $(".header").removeClass("on");
          $(".guideBtn").removeClass("on");
        }

        // 모바일 분기에서만 section1 일 때 / 세로 모드 일 때만 로고 색 변경
        if (
          nowWid < 1024 &&
          index == 1 &&
          window.matchMedia("(orientation: portrait)").matches
        ) {
          setState(true);
        }
      },
    });
  });

  return (
    <Container id="fullpage">
      <Div className="section">
        <SectionA language={language} />
      </Div>
      <Div className="section">
        <SectionB language={language} />
      </Div>
      <Div className="section">
        <SectionC language={language} />
      </Div>
      <Div className="section">
        <SectionD language={language} />
      </Div>
      <Div className="section">
        <SectionE language={language} />
      </Div>
      <Div className="section">
        <SectionF language={language} />
      </Div>
      <Div className="section fp-auto-height footer">
        <Footer language={language} />
      </Div>
    </Container>
  );
}

const Container = styled.div``;

const Div = styled.div`
  position: relative;
  overflow: hidden;
`;
