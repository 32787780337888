//css
import styled from "styled-components";

// component
import { UpAni } from "../../utils/media/Ani";

// img
import BackgroundImg from "../../assets/img/second_background.png";
import DeliveryIcon from "../../assets/img/icon/icon_delivery.svg";
import ListBackA from "../../assets/img/list_back_01.png";
import ListBackB from "../../assets/img/list_back_02.png";
import ListBackC from "../../assets/img/list_back_03.png";
import ListBackD from "../../assets/img/list_back_04.png";
import ListBackE from "../../assets/img/list_back_05.png";
import ListBackF from "../../assets/img/list_back_06.png";

export default function SectionD() {
  return (
    <Container>
      <Background src={BackgroundImg} />

      <Wrapper>
        <FirstArea className="sec4_ani">
          <FirstInnerBox>
            <Text>
              <SmallText>하나만 구매해도,</SmallText>
              <MiddleText className="white-text">365일 전 상품</MiddleText>
              <BigText className="white-text"><Point>무료배송</Point></BigText>
            </Text>

            <IconItem>
              <Icon src={DeliveryIcon} />
            </IconItem>
          </FirstInnerBox>
        </FirstArea>

        <SecondArea className="sec4_ani">
          <SecondInnerBox>
            
            <Text>
              <MiddleText className="second-text">AI 알고리즘을 통한</MiddleText>
              <BigText className="second-text">1 : 1 <Point>취향 추천</Point></BigText>
              <Description>나에게 꼭 맞는 상품을<br className="mobile-br" /> 보여드려요 !</Description>
            </Text>

            <CategoryList>
              <CategoryItem>
                <BackImg src={ListBackA}></BackImg>
                <Category>#러블리</Category>
              </CategoryItem>
              <CategoryItem>
                <BackImg src={ListBackB}></BackImg>
                <Category>#시크</Category>
              </CategoryItem>
              <CategoryItem className="modern-item">
                <BackImg src={ListBackC}></BackImg>
                <Category className="yellow-text">#모던</Category>
              </CategoryItem>
              <CategoryItem>
                <BackImg src={ListBackD}></BackImg>
                <Category>#스포티</Category>
              </CategoryItem>
              <CategoryItem className="romantic-item">
                <BackImg src={ListBackE}></BackImg>
                <Category className="yellow-text">#로맨틱</Category>
              </CategoryItem>
              <CategoryItem>
                <BackImg src={ListBackF}></BackImg>
                <Category>#캐쥬얼</Category>
              </CategoryItem>
            </CategoryList>

          </SecondInnerBox>
        </SecondArea>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F7;
`;

const Background = styled.img`
  position: absolute;
  left: 50%;
  bottom: calc(100vh * (46 / 1080));
  transform: translateX(-50%);
  width: calc(100vh * (901 / 1080));
  height: calc(100vh * (901 / 1080));
  z-index: 1;

  
  @media (max-width: 770px) {
    top: 50%;
    bottom: unset;
    width: calc(100vh * (487 / 862));
    height: calc(100vh * (487 / 862));
    margin: 0;
    transform: translate(-50%, -50%);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: calc(100vh * (82 / 1080));
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100vh * (920 / 1080));
  height: calc(100vh * (847 / 1080));
  z-index: 2;
  
  @media (max-width: 770px) {
    width: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: calc(100vh * (37 / 862));
  }
`

const FirstArea = styled.div`

  ${UpAni()}

  &.on {
    transition-delay: 0.2s;
  }
`

const FirstInnerBox = styled.div`
  width: calc(100vh * (448 / 1080));
  padding: calc(100vh * (65 / 1080)) calc(100vh * (60 / 1080));
  border-radius: calc(100vh * (45 / 1080));
  background-color: #000;
  
  @media (max-width: 770px) {
    width: calc(100vh * (222 / 862));
    padding: calc(100vh * (30 / 862)) calc(100vh * (25 / 862));
    border-radius: calc(100vh * (22 / 862));
  }
`
const Text = styled.div`
  margin-bottom: calc(100vh * (70 / 1080));

  @media (max-width: 770px) {
    margin-bottom: calc(100vh * (20 / 862));
  }
`
const SmallText = styled.small`
  font-weight: 800;
  font-size: calc(100vh * (34 / 1080));
  color: #fff;

  @media (max-width: 770px) {
    font-size: calc(100vh * (20 / 862));
  }
`
const MiddleText = styled.span`
  display: block;
  margin-bottom: calc(100vh * (15 / 1080));
  font-weight: 800;
  font-size: calc(100vh * (36 / 1080));

  &.white-text {
    margin-top: calc(100vh * (30 / 1080));
    color: #fff;
  }
  
  @media (max-width: 770px) {
    font-size: calc(100vh * (23 / 862));
    margin-bottom: calc(100vh * (10 / 862));
    
    &.white-text {
      margin-top: calc(100vh * (15 / 862));
    }
    &.second-text {
      font-size: calc(100vh * (18 / 862));
      margin-bottom: calc(100vh * (3 / 862));
    }
  }
`
const BigText = styled.strong`
  font-weight: 800;
  font-size: calc(100vh * (45 / 1080));
  
  &.white-text {
    color: #fff;
  }
  @media (max-width: 770px) {
    font-size: calc(100vh * (30 / 862));

    &.second-text {
      font-size: calc(100vh * (28 / 862));
    }
  }
`
const Point = styled.em`

  font: inherit;
	text-emphasis-style : dot;
  text-emphasis-color: #FFE500;
`
const IconItem = styled.div`
  width: calc(100vh * (289 / 1080));
  height: calc(100vh * (126 / 1080));
  margin: 0 calc(100vh * (10 / 1080)) 0 calc(100vh * (20 / 1080));
  overflow: hidden;

  
  @media (max-width: 770px) {
    width: calc(100vh * (142 / 862));
    height: calc(100vh * (63 / 862));
    margin: 0 calc(100vh * (10 / 862)) 0 calc(100vh * (20 / 862));
  }
`
const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`

const SecondArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${UpAni()}

  &.on {
    transition-delay: 0.6s;
  }
`
const SecondInnerBox = styled.div`
  width: calc(100vh * (448 / 1080));
  padding: calc(100vh * (65 / 1080)) calc(100vh * (60 / 1080));
  background: #FFF;
  box-shadow: 0px 0px 10px 12px rgba(0, 0, 0, 0.03);
  border-radius: calc(100vh * (45 / 1080));

  
  @media (max-width: 770px) {
    width: calc(100vh * (222 / 862));
    padding: calc(100vh * (30 / 862)) calc(100vh * (25 / 862));
    border-radius: calc(100vh * (22 / 862));
  }
`
const Description = styled.p`
  margin-top: calc(100vh * (25 / 1080)); 
  font-weight: 500;
  font-size: calc(100vh * (23 / 1080));
  color: #1F1F1F;

  &.mobile-br {
    display: none;
  }
  
  @media (max-width: 770px) {
    margin-top: calc(100vh * (10 / 862)); 
    font-size: calc(100vh * (13 / 862));
    line-height: 1.5;

    &.mobile-br {
      display: block;
    }
  }
`

const CategoryList = styled.ul` 
  display: flex;
  flex-wrap: wrap;
  width: calc(100vh * (300 / 1080));
  border-radius: calc(100vh * (8 / 1080));

  @media (max-width: 770px) {
    width: calc(100vh * (150 / 862));
    border-radius: calc(100vh * (5 / 862));
  }
`
const CategoryItem = styled.li`
  position: relative;
  width: calc(100vh * (100 / 1080));
  height: calc(100vh * (100 / 1080));

  &.modern-item {
    transform: translate(calc(100vh * (30 / 1080)), calc(100vh * (-11 / 1080)));
  }
  &.romantic-item {
    transform: translate(calc(100vh * (-19 / 1080)), calc(100vh * (-27 / 1080)));
  }

  @media (max-width: 770px) {
    width: calc(100vh * (50 / 862));
    height: calc(100vh * (50 / 862));

    &.modern-item {
      transform: translate(calc(100vh * (14 / 862)), calc(100vh * (-5 / 862)));
    }
    &.romantic-item {
      transform: translate(calc(100vh * (-9 / 862)), calc(100vh * (-13 / 862)));
    }
  }
`
const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Category = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: calc(100vh * (23 / 1080));
  color: #7C7C7C;

  &.yellow-text {
    color: #FFF200;
  }
  
  @media (max-width: 770px) {
    font-size: calc(100vh * (13 / 862));
    font-weight: 600;
  }
`