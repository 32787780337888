
//css
import styled from "styled-components"

//img
import LogoImg from "../../assets/img/footer_logo.png"
import InstagramImg from "../../assets/img/icon/icon_insta.svg"
import YoutubeImg from "../../assets/img/icon/icon_youtube.svg"
import KakaoImg from "../../assets/img/icon/icon_kakaotalk.svg"

export default function Footer(){

    return(
        <Container>
            <Wrap>
                <LogoWrap>
                    <Logo src={LogoImg} alt="logo"/>
                </LogoWrap>

                <TextCon>
                    <TextBox>
                        <TextItem>
                            <Category>사업자명 : </Category>
                            <Content>WALA Inc.</Content>
                        </TextItem>

                        <TextItem className="line-item">
                            <Category>사업자등록번호 : </Category>
                            <Content>271-86-01681</Content>
                        </TextItem>
                    </TextBox>

                    <TextItem>
                        <Category>주소 : </Category>
                        <Content>서울특별시 강남구 테헤란로 520 삼안빌딩 10층</Content>
                    </TextItem>
                </TextCon>
                
                <IconWrapper>
                    <IconBox href="">
                        <IconImg src={InstagramImg} alt="sns icon"></IconImg>
                    </IconBox>

                    <IconBox href="">
                        <IconImg src={KakaoImg} alt="sns icon"></IconImg>
                    </IconBox>

                    <IconBox href="">
                        <IconImg src={YoutubeImg} alt="sns icon"></IconImg>
                    </IconBox>
                </IconWrapper>
            </Wrap>
        </Container>
    );
}

const Container = styled.div`
    background-color: #000;
`

const Wrap = styled.div`
    width: 945px;
    height: calc(100vh * (150 / 1080));
    padding: 0 calc(100vh * (25 / 1080));
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(100vh * (14 / 1080));
    font-weight: 500;
    color: #AAAAAA;

    @media(max-width: 945px){
        width: 100%;
    }

    @media (max-width: 600px) {
        display: block;
        height: auto;
        padding: calc(100vh * (25 / 1080));
    }
`

const LogoWrap = styled.div`
    width: calc(100vh * (166 / 1080));

    @media (max-width: 600px) {
        display: block;
        height: auto;
        margin-bottom: calc(100vh * (15 / 852));
    }
`

const Logo = styled.img`
    width: 100%;
`

const TextCon = styled.div`
    width: auto;
    margin: 0 auto;
    /* width: calc(100vh * (365 / 1080)); */

    @media (max-width: 600px) {
        margin-bottom: calc(100vh * (15 / 852));
    }
`

const TextBox = styled.div`
        display: flex;
        align-items: center;
        margin-bottom: calc(100vh * (13 / 1080));
`
const TextItem = styled.div`    
    /* display: flex;  */

    &.line-item:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: calc(100vh * (12 / 1080));
        margin: 0 calc(100vh * (24 / 1080));
        background-color: #474747;
    }

`

const Category = styled.span`
    font-size: calc(100vh * (14 / 1080));
    font-weight: 500;
    color: #848484;

    @media (max-width: 600px) {
        font-size: calc(100vh * (12 / 852));
    }
`

const Content = styled.span`
    font-size: calc(100vh * (14 / 1080));
    font-weight: 500;
    color: #AEAEAE;
    
    @media (max-width: 600px) {
        font-size: calc(100vh * (12 / 852));
    }
`

const IconWrapper = styled.div`
    display: flex;
    gap: 16px;
`
const IconBox = styled.a`
    display: block;
    width: 20px;
    height: 20px;
    
    @media (max-width: 600px) {
        width: calc(100vh * (30 / 852));
        height: calc(100vh * (30 / 852));
    }

`

const IconImg = styled.img`
    width: 100%;
`