import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Switch ,Link} from "react-router-dom";

// css
import './App.css';

// page
import MainPage from './views/mainPage';


function App() {
  return (
    <Router>
      <Route exact path = "/" component={MainPage}/>
    </Router>
  );
}

export default App;
