//css
import styled from "styled-components";

// component
import { UpAni } from "../../utils/media/Ani";

// img
import ListImgA from "../../assets/img/celebrity_img_01.png";
import ListImgB from "../../assets/img/celebrity_img_02.png";
import ListImgC from "../../assets/img/celebrity_img_03.png";

export default function SectionC() {

  return (
    <Container>
      
      <ImgWrapper className="sec3_ani">

        <BackTextBox>
          <BackText>셀럽이 입은 이 옷,<br />
            넌 모르는 그 옷.
          </BackText>
        </BackTextBox>
        
        <ImgList>
          <ImgItem>
            <ListImg src={ListImgA}></ListImg>
          </ImgItem>
          <ImgItem>
            <ListImg src={ListImgB}></ListImg>
          </ImgItem>
          <ImgItem>
            <ListImg src={ListImgC}></ListImg>
          </ImgItem>
        </ImgList>
        
        
      </ImgWrapper>

      <TextBox className="sec3_ani">
        <TitleText>저장하고 구매까지 <Point>한 번에</Point></TitleText>
        <SubText>미디어 속 셀럽들의 패션 정보를<br className="mobile-br" /> 가장 빠르게 만나 볼 수 있는 이곳.</SubText>
      </TextBox>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: calc(100vh * (65 / 1080));

  ${UpAni()};
  `
const BackTextBox = styled.div`
  width: calc(100vh * (502 / 1080));
  height: calc(100vh * (502 / 1080));
  padding: calc(100vh * (80 / 1080)) 0;
  text-align: center;
  border-radius: 50%;
  background-color: #000;
  
  @media (max-width: 700px) {
    width: calc(100vh * (253 / 862));
    height: calc(100vh * (253 / 862));
    padding: calc(100vh * (45 / 1080)) 0;
  }
`
const BackText = styled.p`
  font-weight: 800;
  font-size: calc(100vh * (35 / 1080));
  line-height: 1.4;
  color: #fff;
  
  @media (max-width: 700px) {
    font-size: calc(100vh * (19 / 862));
    line-height: 1.7;
  }
`
const ImgList = styled.ul`
  position: absolute;
  bottom: calc(100vh * (-27 / 1080));
  left: 50%;
  display: flex;
  justify-content: center;
  gap: calc(100vh * (24 / 1080));
  transform: translateX(-50%);
  
  @media (max-width: 700px) {
    bottom: 0;
    padding: 0 calc(100vh * (38 / 862));
    gap: calc(100vh * (10 / 862));
  }
`
const ImgItem = styled.li`
  width: calc(100vh * (230 / 1080));
  height: calc(100vh * (323 / 1080));
  overflow: hidden;
  
  @media (max-width: 700px) {
    width: calc(100vh * (99 / 862));
    height: calc(100vh * (140 / 862));
  }
`
const ListImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const TextBox = styled.div`
  text-align: center;
  ${UpAni()};
  
  &.on {
    transition-delay: 0.4s;
  }
`
const TitleText = styled.h2` 
  margin-bottom: calc(100vh * (45 / 1080));
  font-weight: 800;
  font-size: calc(100vh * (48 / 1080));
  
  @media (max-width: 700px) {
    font-size: calc(100vh * (30 / 862));
  }
`
const SubText = styled.p`
  font-weight: 500;
  font-size: calc(100vh * (23 / 1080));

  & .mobile-br {
    display: none;
  }
  
  @media (max-width: 700px) {
    line-height: 1.7;
    & .mobile-br {
      display: block;
    }
  }
`
const Point = styled.em`
  font: inherit;
	text-emphasis-style : dot;
  text-emphasis-color: #FFE500;

  @media (max-width: 700px) {
    display: block;
  }
`