
//css
import styled from "styled-components";

// component
import { UpAni } from "../../utils/media/Ani";

// img
import BackgroundImg from "../../assets/img/second_background.png";
import ApparelImg from "../../assets/img/apparel_img.png";
import OutdoorImg from "../../assets/img/outdoor_img.png";
import JewelryImg from "../../assets/img/jewelry_img.png";
import ShoesImg from "../../assets/img/shoes_img.png";
import AthleisureImg from "../../assets/img/athleisure_img.png";
import KidsImg from "../../assets/img/kids_img.png";

export default function SectionB() {


  return (
    <Container>
      <Background src={BackgroundImg} />
      <Wrapper>
        <ImgList className="sec2_ani">
          <ImgItem className="white-left-box">
            <InnerText className="white-text">OUTDOOR</InnerText>
            <InnerImg src={OutdoorImg}></InnerImg>
          </ImgItem>
          <ImgItem>
            <InnerText>JEWELRY</InnerText>
            <InnerImg src={JewelryImg}></InnerImg>
          </ImgItem>
          <ImgItem className="margin-box">
            <InnerText>SHOES</InnerText>
            <InnerImg src={ShoesImg}></InnerImg>
          </ImgItem>
          <ImgItem>
            <InnerText className="white-text">ATHLEISURE</InnerText>
            <InnerImg src={AthleisureImg}></InnerImg>
          </ImgItem>
          <ImgItem className="white-right-box">
            <InnerText>KIDS</InnerText>
            <InnerImg src={KidsImg}></InnerImg>
          </ImgItem>
        </ImgList>

        <MainTextCon className="sec2_ani">
          <TitleText>우리가 <Point>찾던</Point> 패션</TitleText>
          <SubText>가장 트렌디한 패션<br /> 정보를 <b>WALALAND</b>에서<br /> 만나보세요.</SubText>
        </MainTextCon>

        
        <ImgCon className="sec2_ani">
          <CenterImg src={ApparelImg}></CenterImg>
          {/* <BottomImg className="sec2_ani" src={SecBImg} alt="section2_img" /> */}
          <InnerText className="white-text">APPAREL</InnerText>
        </ImgCon>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f6f6f6;
`;

const Background = styled.img`
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  width: calc(100vh * (901 / 1080));
  height: calc(100vh * (901 / 1080));
  z-index: 0;

  @media (max-width: 700px) {
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: unset;
    width: calc(100vh * (482 / 862));
    height: calc(100vh * (482 / 862));
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const ImgList = styled.ul`
  display: flex;
  margin-top: calc(100vh * (100 / 1080));
  ${UpAni()};
  
  &.on {
    transition-delay: 0.4s;
  }

  
  @media (max-width: 1024px) {
    position: absolute;
    bottom: calc(100vh * (100 / 1080));
  }
  @media (max-width: 700px) {
    bottom: calc(100vh * (127 / 862));
  }
`
const ImgItem = styled.li`
  position: relative;
  width: calc(100vh * (345 / 1080));
  height: calc(100vh * (507 / 1080));
  overflow: hidden;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.2);

  &.margin-box {
    margin-left: calc(100vh * (290 / 1080));
  }

  &.white-left-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 71.08%);
    z-index: 10;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  &.white-right-box::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 71.08%);
    z-index: 10;
  }

  
  @media (max-width: 1024px) {
    
    width: calc(100vh * (250 / 1080));
    height: calc(100vh * (326 / 1080));

    &.margin-box {
      margin-left: 0;
    }
  }

  @media (max-width: 700px) {
    width: calc(100vh * (116 / 862));
    height: calc(100vh * (151 / 862));

    &.margin-box {
      margin-left: 0;
    }
  }

`
const InnerText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: calc(100vh * (38 / 1080));
  color: #000;

  &.white-text {
    color: #fff;
  }
  @media (max-width: 700px) {
    font-size: calc(100vh * (13 / 862));
  }

`

const InnerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`

const MainTextCon = styled.div`
  position: absolute;
  top: calc(100vh * (135 / 1080));
  left: 47%;
  transform: translateX(-47%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: calc(100vh * (433 / 1080));
  height: calc(100vh * (433 / 1080));
  border-radius: 50%;
  ${UpAni()};

  
  @media (max-width: 1024px) {
    top: calc(100vh * (100 / 1080));
    width: calc(100vh * (380 / 1080));
    height: calc(100vh * (380 / 1080));
  }
  @media (max-width: 700px) {
    left: 20%;
    transform: translateX(-20%);
    top: calc(100vh * (122 / 862));
    width: calc(100vh * (247 / 862));
    height: calc(100vh * (247 / 862));
  }
`;

const TitleText = styled.strong`
font-weight: 800;
font-size: calc(100vh * (38 / 1080));
color: #fff;

  @media (max-width: 700px) {
    font-size: calc(100vh * (24 / 862));
  }
`;

const Point = styled.em`
  font: inherit;
	text-emphasis-style : dot;
  text-emphasis-color: #FFE500;
  `

const SubText = styled.p`
  margin: calc(100vh * (60 / 1080)) 0 0 18px;
  font-weight: 500;
  font-size: calc(100vh * (23 / 1080));
  color: #fff;
  line-height: 1.5;

  
  @media (max-width: 1024px) {
    margin: calc(100vh * (40 / 1080)) 0 0 0;
  }
  @media (max-width: 700px) {
    margin: calc(100vh * (15 / 862)) 0 0 0;
    font-size: calc(100vh * (14 / 862));
    line-height: 1.7;
  }
`

const ImgCon = styled.div`
  position: absolute;
  left: 30%;
  /* transform: translateX(-30%); */
  transform: translate(-30%, 15%);
  width: calc(100vh * (454 / 1080));
  margin-top: calc(100vh * (100 / 1080));
  
  ${UpAni()};
  
  &.on {
    transition-delay: 0.8s;
    transform: translate(0, 0);
  }

  @media (max-width: 1024px) {
    position: unset;
    transform: translate(-30%, 0);
    width: calc(100vh * (330 / 1080));
    
    &.on {
      transition-delay: 0.8s;
      transform: translate(-30%, -15%);
    }
  }
  @media (max-width: 700px) {
    position: unset;
    transform: translate(-30%, 15%);
    width: calc(100vh * (200 / 862));
    
    &.on {
      transition-delay: 0.8s;
      transform: translate(-30%, 0);
    }
  }
`;

const CenterImg = styled.img`
  width: 100%;
`;


