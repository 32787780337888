//css
import styled from "styled-components";

// component
import { UpAni } from "../../utils/media/Ani";

// img
import BackgroundImg from "../../assets/img/second_background.png";
import BrandImg from "../../assets/img/brand_img.png";

export default function SectionE() {
  return (
    <Container>

      <Background src={BackgroundImg}></Background>

      <Wrapper>
        <TextBox className="sec5_ani">
          <TitleText>브랜드 입점 <Point>혜택</Point></TitleText>
          <Description>상품 협찬과 <Bold>스타일리스트 PICK</Bold>을 통한<br />  
            <SubPoint>셀럽 CONTENTS</SubPoint> 생성이 가능해요.
          </Description>
        </TextBox>

        <ImgBox className="sec5_ani">
          <Img src={BrandImg}></Img>
        </ImgBox>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Background = styled.img`
  position: absolute;
  left: 50%;
  bottom: -100%;
  transform: translateX(-50%);
  width: calc(100vh * (1670 / 1080));
  height: calc(100vh * (1670 / 1080));
  z-index: 0;

  
  @media (max-width: 650px) {
    bottom: calc(100vh * (36 / 862));
    width: calc(100vh * (545 / 862));
    height: calc(100vh * (545 / 862));
  }
`;

const Wrapper = styled.div`
  margin-top: calc(100vh * (150 / 1080));

  @media (max-width: 650px) {
    margin-top: calc(100vh * (140 / 862));
  }
`;
const TextBox = styled.div`
  margin-bottom: calc(100vh * (60 / 1080));
  text-align: center;

  ${UpAni()}

  &.on {
    transition-delay: 0.2s;
  }
  
  @media (max-width: 650px) {
    margin-bottom: calc(100vh * (100 / 862));
  }
`;

const TitleText = styled.h2`
  margin-bottom: calc(100vh * (35 / 1080));
  font-weight: 800;
  font-size: calc(100vh * (48 / 1080));

  @media (max-width: 650px) {
    margin-bottom: calc(100vh * (45 / 862));
    font-size: calc(100vh * (30 / 862));
  }
`;

const Point = styled.em`
  font: inherit;
	text-emphasis-style : dot;
  text-emphasis-color: #FFE500;
`


const Description = styled.p`
  font-weight: 500;
  font-size: calc(100vh * (34 / 1080));
  line-height: 1.4;

  @media (max-width: 650px) {
    font-size: calc(100vh * (20 / 862));
    line-height: 1.7;
  }
`;

const Bold = styled.b`
  font-weight: 700;
`
const SubPoint = styled.b`
  position: relative;
  font-weight: 700;
  box-shadow: inset 0 calc(100vh * (-32 / 1080)) 0px rgba(255, 255, 51, 0.9);
  
  @media (max-width: 650px) {
  box-shadow: inset 0 calc(100vh * (-20 / 1080)) 0px rgba(255, 255, 51, 0.9);
  }
`

const ImgBox = styled.div`
  width: calc(100vh * (682 / 1080));
  height: calc(100vh * (589 / 1080));
  margin: 0 auto;
  
  ${UpAni()}

  &.on {
    transition-delay: 0.6s;
  }

  @media (max-width: 650px) {
    width: calc(100vh * (334 / 862));
    height: calc(100vh * (288 / 862));
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
