import React, {useEffect, useState} from 'react';
import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

//css
import styled from "styled-components";

//img
import Menu1 from "../../assets/img/menu_01.jpg"
import Menu2 from "../../assets/img/menu_02.jpg"
import Menu3 from "../../assets/img/menu_03.jpg"
import Menu4 from "../../assets/img/menu_04.jpg"
import Menu5 from "../../assets/img/menu_05.jpg"
import Menu6 from "../../assets/img/menu_06.jpg"
import Menu7 from "../../assets/img/menu_07.jpg"
import SideBtn from "../../assets/img/icon/icon_side.svg"
import MobileSideBtn from "../../assets/img/icon/icon_side_mb.svg"
import CloseBtn from "../../assets/img/icon/icon_close_menu.svg"

export default function Side() {
    
    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
        setIsActive(!isActive);
    }

    return (
        <>
        <MobileButton onClick={toggleActive}>
            <MobileButtonImg src={MobileSideBtn} />
        </MobileButton>
        <Container className={isActive ? "active" : ""}>
            <SideMenuWrapper>
                <MenuCloseButton onClick={toggleActive}>
                    <CloseButtonImg src={CloseBtn} />
                </MenuCloseButton>
                <SideMenu>
                    <MenuList>
                        <MenuImg src={Menu1} />
                        <MenuLink href="https://wala-store.com/" >WALALAND</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu2} />
                        <MenuLink href="https://wala.land/store" target="_blank">WALALAND STORE</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu3} />
                        <MenuLink href="https://wala.land/" target="_blank">WALALAND B2B</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu4} />
                        <MenuLink href="http://wala-ict.com/html/main/main" target="_blank">WALA ICT</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu5} />
                        <MenuLink href="http://rowelln.com/html/main" target="_blank">ROWELL</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu6} />
                        <MenuLink href="https://wala-designlab.com/" target="_blank">DESIGN LAB</MenuLink>
                    </MenuList>
                    <MenuList>
                        <MenuImg src={Menu7} />
                        <MenuLink href="https://nodebemade.com/" target="_blank">NODEBEMADE</MenuLink>
                    </MenuList>
                </SideMenu>
            </SideMenuWrapper>
            <SideButton onClick={toggleActive}>
                <SideButtonImg src={SideBtn} />
            </SideButton>
        </Container>
    </>
    )
}

const Container = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    z-index: 150;
    transition: 0.5s;
    transform: translateX(calc(100vh * (392 / 1080)));

    &.active {
        transform: translateX(0);
    }
    
    @media (max-width: 600px) {
        transform: translateX(calc(100vh * (287 / 862)));
    }
`
const MobileButton = styled.button`
    display: none;

     
    @media (max-width: 600px) {
      display: block;
      position: fixed;
      right: calc(100vh * (24 / 862));
      top: calc(100vh * (16 / 862));
      width: calc(100vh * (25 / 862));
      height: calc(100vh * (25 / 862));
      z-index: 145;
    }
`
const MobileButtonImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const SideMenuWrapper = styled.div`
    width: calc(100vh * (392 / 1080));
    height: 100vh;
    background: rgba(0,0,0,0.5);

    
    @media (max-width: 600px) {
        position: relative;
        padding-top: calc(100vh * (51 / 862));
        width: calc(100vh * (287 / 862));
        background: rgba(0,0,0,0.7);
    }
`
const MenuCloseButton = styled.button`
    display: none;

     
    @media (max-width: 600px) {
        position: absolute;
        right: calc(100vh * (16 / 862));
        top: calc(100vh * (13 / 862));
        display: block;
        width: calc(100vh * (21 / 862));
        height: calc(100vh * (21 / 862));
    }
`
const CloseButtonImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const SideMenu = styled.ul``
const MenuList = styled.li`
    position: relative;
    width: 100%;
    height: calc(100vh * (105 / 1080));
    overflow: hidden;
    
    @media (max-width: 600px) {
        height: calc(100vh * (91 / 862));
    }
`
const MenuLink = styled.a`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    font-size: calc(100vh * (33 / 1080));
    font-weight: 700;
    color: #fff;
    transition: 0.6s;
    background: rgba(0, 0, 0, 0.6);

    &:hover {
        background: rgba(0, 0, 0, 0.2);
    }
    
    @media (max-width: 600px) {
        font-size: calc(100vh * (23 / 862));
        background: rgba(0, 0, 0, 0.7);
    }
`
const MenuImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const SideButton = styled.button`
    position: absolute;
    bottom: calc(100vh * (140 / 1080));
    left: calc(100vh * (-60 / 1080));
    width: calc(100vh * (60 / 1080));
    height: calc(100vh * (60 / 1080));

    
  @media (max-width: 600px) {
    display: none;
  }
`
const SideButtonImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`