import SwiperCore, { Autoplay } from "swiper";

//css
import styled from "styled-components";
import "swiper/swiper-bundle.css";

import BigBackgroundImg from "../../assets/img/yellow_background.png";
import SmallBackgroundImg from "../../assets/img/yellow_small_background.png";
import BigLogoImg from "../../assets/img/main_logo.png";
import IphoneImg from "../../assets/img/iphone_img.png";

// component

export default function SectionA() {

  SwiperCore.use([Autoplay]);


  return (
    <Container>
      <Wrap>
        <TextCon className="sec1_ani">
          <TextB className="text">
            App For You
          </TextB>
          <BigLogo src={BigLogoImg}></BigLogo>
          <SmallBackground src={SmallBackgroundImg}></SmallBackground>
        </TextCon>
        <BigBackground src={BigBackgroundImg}></BigBackground>
      
        <PhoneImg className="sec1_ani" src={IphoneImg} />
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;


`;

const Wrap = styled.div`
  width: 945px;
  margin: 0 auto;

  
  @media (max-width: 1024px) and (orientation: portrait){
  }
  
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 calc(100vh * (25 / 862)) ;
  }
`;

const TextCon = styled.div`
  width: 100%;
  margin-top: calc(100vh * (134 / 1080));
  padding-left: 55px;
  position: relative;


  @media (max-width: 1366px) and (orientation: landscape) {
    /* width: calc(100vw * (702 / 1920)); */

  }

  @media (max-width: 1024px){
    /* width: calc(100vw * (702 / 1920)); */

  }

  @media (max-width: 1024px) and (orientation: portrait) {
    /* width: 100%; */
    /* margin-top: calc(100vh * (42 / 1080));
    word-break: keep-all; */

  }

  @media (max-width: 600px) {
    padding-left: 0;
    margin-top: calc(100vh * (160 / 862))
  }
`;

const BigLogo = styled.img`
  width: calc(100vh * (520 / 1080));
/* 
  @media (max-width: 1450px) and (orientation: landscape) {
    width: calc(100vw * (520 / 1920));
  } */

  @media (max-width: 1024px) {
    /* font-size: calc(100vw * (70 / 1920)); */
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    /* font-size: calc(100vw * (35 / 468)); */
  }
  
  @media (max-width: 600px)  {
    width: calc(100vh * (259 / 862));
  }
`;

const TextB = styled.span`
  display:block;
  font-size: calc(100vh * (38 / 1080));
  font-weight: 700;
  margin-bottom: calc(100vh * (40 / 1080));

  
  @media (max-width: 600px)  {
    width: calc(100vh * (620 / 862));
    margin-bottom: calc(100vh * (35 / 1080));
    font-size: calc(100vh * (23 / 862));
  }
`

const BigBackground = styled.img`
  position: absolute;
  left: calc(100vh * (-400 / 1080));
  top: calc(100vh * (130 / 1080));
  width: calc(100vh * (1020 / 1080));
`

const SmallBackground = styled.img`
  position: absolute;
  top: calc(100vh * (-20 / 1080));
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: calc(100vh * (490 / 1080));
`
const PhoneImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 60%;
  transform: translateX(-60%);
  width: calc(100vh * (1345 / 1080));
  
  @media (max-width: 900px)  {
    /* position: unset;
    transform: unset;
    left: unset;
    margin: 0 auto; */
    width: calc(100vh * (800 / 862));
  }
  @media (max-width: 600px) {
    width: calc(100vh * (620 / 862));
    bottom: calc(100vh * (126 / 862));
  }
`
